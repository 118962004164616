<script>

import Layout from "../../../layouts/main.vue";
import appConfig from "../../../../app.config";
import axios from "axios";
import Swal from "sweetalert2";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import calendar from "dayjs/plugin/calendar";

dayjs.extend(relativeTime);
dayjs.extend(calendar);

export default {
  page: {
    title: "Profile",
    meta: [{ name: "description", content: appConfig.description }],
  },
  setup() {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })

    return { Toast }
  },
  data() {
    return {
      title: "Profile",
      items: [
        {
          text: "Velzon",
          href: "/",
        },
        {
          text: "Profile",
          active: true,
        },
      ],
      thumbsSwiper: null,
      user: JSON.parse(localStorage.getItem("user")),
      logbackup: []
    };
  },
  methods: {
    async backupChat(){
      var btnBackup = document.querySelector("#btn-backup")

      btnBackup.innerHTML = "Loading..."
      btnBackup.setAttribute("disabled","disabled");
      const date = new Date();
      let day = date.getDate()
      let month = date.getMonth() + 1
      let year = date.getFullYear()

      let localContact = localStorage.getItem("Contact");
      let res;

      this.logbackup.push({
        title: 'Please wait backup contact',
        message: 'backup contact starting...',
        time: date.getHours()+":"+date.getMinutes()+" - "+ day + "/" + month + "/" + year,
        icon: 'ri-error-warning-fill',
        type: 'warning'
      })

      if(localContact === null){
        let contactLength = localStorage.getItem("ContactLength");
        let start = 0;
        let count = 50;
        let pageLength = Math.round(parseInt(contactLength) / count);
        let datatemp = {
          data: {
            Data: []
          }
        };

        for (let page = 0; page <= pageLength; page++) {
          var baseUrl = process.env.VUE_APP_URL_API
          const userChat = await axios.get(baseUrl + "customer-api/searchCustomer", {
            params: {
              ClientID: process.env.VUE_APP_CLIENT_ID,
              AccessToken: localStorage.getItem("jwt"),
              Start: start,
              Count: count,
              Kodemb: JSON.parse(localStorage.getItem("user")).Company.CompanyCode
            }
          });

          res = userChat.data;          
          if(res.Meta.Code !== 200){
            this.Toast.fire({
              icon: 'error',
              title: res.Meta.Message
            })
            return;
          }
          
          for (let _i = 0; _i < res.Data.length; _i++) {
            const item = res.Data[_i];
            datatemp.data.Data.push(item);
          }

          start += count;
        }

        res = datatemp.data;
      } else {
        res = JSON.parse(localContact).data;
      }

      let oneWeekAgo = date.getTime() - (7 * 24 * 60 * 60 * 1000);
      let phoneArr = [];
      let paramsBackupChat = [];
      for (let _i = 0; _i < res.Data.length; _i++) {
        const item = res.Data[_i];
        let lastContactTime = Date.parse(item.LastChatDate.replace("-",""))
        if(lastContactTime > oneWeekAgo){
          phoneArr.push(item.Phone);
          paramsBackupChat.push({
            phone: parseInt(item.Phone),
            limit: 100,
            fetched: 0,
            kodemb: JSON.parse(localStorage.getItem("user")).Company.CompanyCode,
            download_media: true,
            created_date: parseInt(item.CreatedTime)
          })
        }
      }

      try {
        // Remove All Rows before backup by kode mb
        let urlRemoveRows = process.env.VUE_APP_WA_BASE_URL+"api/drop-by-kodemb";
        const removed = await axios.post(urlRemoveRows, {
          kodemb: JSON.parse(localStorage.getItem("user")).Company.CompanyCode
        })

        let resData = removed.data.data;
        if(resData.errors.length == 0){
          // Backup contact
          let bodyParam = {
            phones: phoneArr,
            kodemb: JSON.parse(localStorage.getItem("user")).Company.CompanyCode
          }
          let backupUrl = process.env.VUE_APP_WA_BASE_URL+"api/backup-contact";

          const backupContact = await axios.post(backupUrl, bodyParam);
          
          if(backupContact.status == 200){
  
            this.logbackup.push({
              title: 'Backup Contact',
              message: 'backup contact success saved',
              time: date.getHours()+":"+date.getMinutes()+" - "+ day + "/" + month + "/" + year,
              icon: 'ri-check-fill',
              type: 'success'
            })
  
            let urlBackupConvert = process.env.VUE_APP_WA_BASE_URL+"api/backup-convertation";
            for (let _i = 0; _i < paramsBackupChat.length; _i++) {
              const item = paramsBackupChat[_i];
              const backupChat = await axios.post(urlBackupConvert, item);
  
              if(backupChat.status == 200){
                this.logbackup.push({
                  title: 'Backup Chat : '+item.phone,
                  message: 'backup chat success saved',
                  time: date.getHours()+":"+date.getMinutes()+" - "+ day + "/" + month + "/" + year,
                  icon: 'ri-check-fill',
                  type: 'success'
                })
              } else {
                this.logbackup.push({
                  title: 'Backup Chat : '+item.phone,
                  message: 'backup chat error saved, error : '+backupChat.statusText,
                  time: day + "/" + month + "/" + year,
                  icon: 'ri-close-line',
                  type: 'error'
                })
              }
            }
  
            this.Toast.fire({
              icon: 'success',
              title: backupContact.statusText
            })
          } else {
  
            this.logbackup.push({
              title: 'Backup Contact',
              message: 'backup contact error saved, error : '+backupContact.statusText,
              time: date.getHours()+":"+date.getMinutes()+" - "+ day + "/" + month + "/" + year,
              icon: 'ri-close-line',
              type: 'error'
            })
  
            this.Toast.fire({
              icon: 'error',
              title: backupContact.statusText
            })
          }
        } else {
          this.Toast.fire({
            icon: 'error',
            title: "backup failss"
          })
        }
      } catch (error) {
        this.Toast.fire({
          icon: 'error',
          title: error.message
        })
      }

      btnBackup.innerHTML = "Backup Chat Data";
      btnBackup.removeAttribute("disabled")
    }
  },
  components: {
    Layout,
  },
};
</script>

<style>
  .imgProfile {
    width: 100px;
    height: 100px;
  }
</style>

<template>
  <Layout>
    <div class="profile-foreground position-relative mx-n4 mt-n4">
      <div class="profile-wid-bg">
        <img
          src="@/assets/images/profile-bg.jpg"
          alt=""
          class="profile-wid-img"
        />
      </div>
    </div>
    <div class="pt-4 mb-4 mb-lg-3 pb-lg-4">
      <div class="row g-4">
        <div class="col-auto">
          <div class="avatar-lg">
            <img
              :src="user.Avatar.Original"
              alt="user-img"
              class="img-thumbnail rounded-circle imgProfile"
            />
          </div>
        </div>
        <!--end col-->
        <div class="col">
          <div class="p-2">
            <h3 class="text-white mb-1">{{ user.Name }}</h3>
            <p class="text-white-75">{{ user.Role.Title }}</p>
            <div class="hstack text-white-50 gap-1">
              <div class="me-2">
                <i
                  class="ri-building-line me-1 text-white-75 fs-16 align-middle"
                ></i
                >{{ user.Company.Name }}
              </div>
            </div>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div>
          <div class="d-flex">
            <!-- Nav tabs -->
            <ul
              class="nav nav-pills animation-nav profile-nav gap-2 gap-lg-3 flex-grow-1"
              role="tablist"
            >
              <li class="nav-item">
                <a
                  class="nav-link fs-14 active"
                  data-bs-toggle="tab"
                  href="#overview-tab"
                  role="tab"
                >
                  <i class="ri-airplay-fill d-inline-block d-md-none"></i>
                  <span class="d-none d-md-inline-block">Overview</span>
                </a>
              </li>
              <li class="nav-item" v-if="user.Role.Code == 'principal'">
                <a
                  class="nav-link fs-14"
                  data-bs-toggle="tab"
                  href="#activities"
                  role="tab"
                >
                  <i class="ri-list-unordered d-inline-block d-md-none"></i>
                  <span class="d-none d-md-inline-block">Backup Chat Data</span>
                </a>
              </li>
            </ul>
            <div class="flex-shrink-0">
              <router-link to="/pages/profile-setting" class="btn btn-success"
                ><i class="ri-edit-box-line align-bottom"></i> Edit Profile</router-link
              >
            </div>
          </div>
          <!-- Tab panes -->
          <div class="tab-content pt-4 text-muted">
            <div class="tab-pane active" id="overview-tab" role="tabpanel">
              <div class="row">
                <div class="col-xxl-3">

                  <div class="card">
                    <div class="card-body">
                      <h5 class="card-title mb-3">Info</h5>
                      <div class="table-responsive">
                        <table class="table table-borderless mb-0">
                          <tbody>
                            <tr>
                              <th class="ps-0" scope="row">Full Name :</th>
                              <td class="text-muted">{{ user.Name }}</td>
                            </tr>
                            <tr>
                              <th class="ps-0" scope="row">Mobile :</th>
                              <td class="text-muted">{{ user.Phone }}</td>
                            </tr>
                            <tr>
                              <th class="ps-0" scope="row">E-mail :</th>
                              <td class="text-muted">{{ user.Email }}</td>
                            </tr>
                            <tr>
                              <th class="ps-0" scope="row">Location :</th>
                              <td class="text-muted">
                                -
                              </td>
                            </tr>
                            <tr>
                              <th class="ps-0" scope="row">Joining Date</th>
                              <td class="text-muted">-</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <!-- end card body -->
                  </div>
                  <!-- end card -->
                </div>
              </div>
              <!--end row-->
            </div>
            <div class="tab-pane fade" id="activities" role="tabpanel" v-if="user.Role.Code == 'principal'">
              <div class="card">
                <div class="card-body p-0">
                  <div class="
                      alert alert-warning
                      border-0
                      rounded-top rounded-0
                      m-0
                      d-flex
                      align-items-center
                    " role="alert">
                    <alert-triangle-icon class="text-warning me-2 icon-sm"></alert-triangle-icon>
                    <div class="flex-grow-1 text-truncate">
                      Chat yang akan di bakcup adalah 7 hari terakhir
                    </div>
                  </div>

                  <div class="row align-items-end">
                    <div class="col-sm-12">
                      <div class="p-3">
                        <p class="fs-16 lh-base">
                          Backup Chat Now
                        </p>
                        <div class="mt-3">
                          <button @click="backupChat" class="btn btn-success" id="btn-backup">Backup Chat Data</button>
                        </div>
                        <div class="d-flex align-items-center">
                          <div class="flex-grow-1">

                              <div class="col-xl-12" >
                                <div class="card card-height-100">
                                  <div class="
                                      card-header
                                      border-bottom-dashed
                                      align-items-center
                                      d-flex
                                    ">
                                    <h4 class="card-title mb-0 flex-grow-1">Log</h4>
                                  </div>
                                  <!-- end cardheader -->
                                  <div class="card-body p-0">
                                    <div data-simplebar style="max-height: 364px" class="p-3">
                                      <div class="acitivity-timeline acitivity-main">

                                        <div class="acitivity-item d-flex" v-for="(logInfo, index) in logbackup" :key="index">
                                          <div class="flex-shrink-0 avatar-xs acitivity-avatar">
                                            <div class="
                                                avatar-title
                                                rounded-circle shadow
                                              " :class="[
                                                {'text-success': logInfo.type === 'success'},
                                                {'text-warning': logInfo.type === 'warning'},
                                                {'text-danger': logInfo.type === 'error'},
                                                {'bg-soft-success': logInfo.type === 'success'},
                                                {'bg-soft-warning': logInfo.type === 'warning'},
                                                {'bg-soft-danger': logInfo.type === 'error'},
                                              ]">
                                              <i :class="logInfo.icon"></i>
                                            </div>
                                          </div>
                                          <div class="flex-grow-1 ms-3">
                                            <h6 class="mb-1">{{ logInfo.title }}</h6>
                                            <p class="text-muted mb-1">
                                              {{ logInfo.message }}
                                            </p>
                                            <small class="mb-0 text-muted">{{ logInfo.time }}</small>
                                          </div>
                                        </div>

                                      </div>
                                    </div>
                                  </div>
                                  <!-- end card body -->
                                </div>
                                <!-- end card -->
                              </div>

                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="col-sm-4">
                      <div class="px-3 m-2">
                        <img src="@/assets/images/user-illustarator-2.png" class="img-fluid" alt="" />
                      </div>
                    </div> -->
                  </div>
                </div>
                <!-- end card-body-->
              </div>
              <!--end card-->
            </div>
            <!--end tab-pane-->
          </div>
          <!--end tab-content-->
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </Layout>
</template>
